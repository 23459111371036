<template>
  <TTView>
    <VRow>
      <VCol>
        <VExpansionPanels
          v-model="panel"
          class="py-3"
        >
          <VExpansionPanel>
            <VExpansionPanelHeader
              expand-icon="fal fa-chevron-down"
            >
              <h2 class="font-weight-bold">
                Новая компания
              </h2>
            </VExpansionPanelHeader>
            <VExpansionPanelContent>
              <VForm
                ref="form"
              >
                <VContainer fluid>
                  <VRow
                    align="center"
                  >
                    <VCol md="6">
                      <div>
                        <strong>Имя</strong>
                      </div>
                      <VTextField
                        v-model="entity.name"
                        :counter="maxNameSize"
                      />
                    </VCol>
                  </VRow>
                  <VRow
                    align="center"
                  >
                    <VCol md="6">
                      <div> <strong>Аккаунт</strong></div>
                      <VSelect
                        v-model="entity.accountId"
                        :items="accounts"
                        item-text="name"
                        item-value="id"
                      />
                    </VCol>
                  </VRow>
                  <VRow
                    align="center"
                  >
                    <VCol md="6">
                      <template v-for="token in tokensArray">
                      <div :key="token.name + 'title'">
                        <strong>{{ token.name }}</strong>
                      </div>
                      <VTextField
                        :key="token.name"
                        v-model="token.token"
                      />
                    </template>
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol md="4">
                      <VBtn
                        large
                        depressedgit
                        color="primary"
                        class="mr-3"
                        :disabled="!isValidCreateForm"
                        @click="save"
                      >
                        Создать компанию
                      </VBtn>
                      <VBtn
                        large
                        text
                        depressed
                        @click="cancel"
                      >
                        Отмена
                      </VBtn>
                    </VCol>
                  </VRow>
                </VContainer>
              </VForm>
            </VExpansionPanelContent>
          </VExpansionPanel>
        </VExpansionPanels>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <CompaniesTable
          :companies="entitiesList"
          :loading="loading"
          :search="searchCompanies"
        >
          <template #topActions>
            <TTTextField
              v-model="searchCompanies"
              placeholder="Фильтр..."
              append-icon="fal fa-search"
            />
          </template>
          <template #rowActions="{ item }">
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  dark
                  color="blue"
                  class="mx-1"
                  :to="{ name : Names.R_ACCOUNT_V2_COMPANY_VIEW,
                         params : { companyId : item.id, accountId : item.accountId } }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-eye
                  </VIcon>
                </VBtn>
              </template>

              <span>Просмотр</span>
            </VTooltip>

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  color="orange"
                  class="mx-1"
                  :disabled="!item.active"
                  :to="{ name : Names.R_ACCOUNT_SYS_COMPANY_EDIT, params : { id : item.id } }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-edit
                  </VIcon>
                </VBtn>
              </template>

              <span>Редактирование</span>
            </VTooltip>

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  dark
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="handleActivation(item.active, item.id)"
                >
                  <VIcon
                    small
                    :color="item.active ? 'red' : 'green'"
                  >
                    fal {{ item.active ? 'fal fa-lock' : 'fal fa-unlock' }}
                  </VIcon>
                </VBtn>
              </template>

              <span>{{ item.active ? 'Деактивировать' : 'Активировать' }}</span>
            </VTooltip>

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  dark
                  exact
                  color="green"
                  class="mx-1"
                  v-bind="attrs"
                  :to="{ name : Names.R_ACCOUNT_SYS_COMPANY_USER_ADD, params : { companyId : item.id } }"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-user-plus
                  </VIcon>
                </VBtn>
              </template>

              <span>Добавить Администратора</span>
            </VTooltip>

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  dark
                  exact
                  color="red"
                  class="mx-1"
                  v-bind="attrs"
                  :to="{ name : Names.R_ACCOUNT_SYS_COMPANY_USER_REMOVE, params : { companyId : item.id } }"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-user-minus
                  </VIcon>
                </VBtn>
              </template>

              <span>Удалить Администратора</span>
            </VTooltip>
          </template>
        </CompaniesTable>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import CompaniesTable from '../../../components/accounts/companies/CompaniesTable.vue';

export default {
  name: 'SysCompanyList',

  components: {
    CompaniesTable,
  },

  inject: ['Names'],

  data() {
    return {
      entity: {
        name: '',
        accountId: '',
      },
      accounts: [],
      entitiesList: [],
      loading: false,
      panel: undefined,
      maxNameSize: 700,
      searchCompanies: '',
      tokensArray: [{
        token: '',
        name: 'Токен для бота Slack',
        botType: 'slack',
      }],
    };
  },

  computed: {
    // TODO: временное решение, как добавится валидация, поменять на rules
    isValidCreateForm() {
      return this.isValidName && this.isValidAccount;
    },
    isValidName() {
      return (this.entity.name?.length <= this.maxNameSize && this.entity.name);
    },
    isValidAccount() {
      return this.entity.accountId;
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const [companiesResponse, accountsResponse] = await Promise.all([
          this.$di.api.Account.indexCompany(),
          this.$di.api.Account.indexAccount(),
        ]);

        if (companiesResponse.companies && accountsResponse.accounts) {
          this.entitiesList = companiesResponse.companies.map((company) => {
            const { name: accountName } = accountsResponse.accounts.find((account) => account.id === company.accountId);
            return { ...company, accountName };
          });
        }

        this.accounts = accountsResponse.accounts || [];
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    cancel() {
      this.$refs.form.reset();
    },
    save() {
      this.$di.api.Account.createCompany({ company: this.entity })
        .then((r) => Promise.all(this.getAddTokenPromises(r.id)))
        .catch(this.$di.notify.errorHandler)
        .then(this.cancel)
        .then(this.fetch);
    },
    getAddTokenPromises(companyId) {
      const addTokenPromises = [];
      this.tokensArray.forEach((token) => {
        if (token.token) {
          addTokenPromises.push(
            new Promise((resolve) => {
              this.$di.api.NotificationBots.addBotToken({
                companyId,
                token: token.token,
                botType: token.botType,
              });
              resolve();
            }),
          );
        }
      });
      return addTokenPromises;
    },

    handleActivation(active, id) {
      if (active) {
        this.$di.api.Account.deactivateCompany({ id })
          .then(this.fetch)
          .catch(this.$di.notify.errorHandler);
      } else {
        this.$di.api.Account.activateCompany({ id })
          .then(this.fetch)
          .catch(this.$di.notify.errorHandler);
      }
    },
  },
};
</script>
